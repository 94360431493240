<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item
        v-for="(item, ind) in $store.state.currMenuPaths"
        :key="ind"
        >{{ item }}</el-breadcrumb-item
      >
    </el-breadcrumb>
    <div id="cont">
      <keep-alive>
      
      <RouterView v-if="$route.meta.keepAlive"/>
      </keep-alive>
      <RouterView v-if="!$route.meta.keepAlive"/>

    
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  height: 44px;
  line-height: 44px;
  border-bottom: 1px solid $color-border-main;
}
#cont {
  padding: 10px 0;
}
</style>